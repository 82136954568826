@import url('https://fonts.googleapis.com/css2?family=Inclusive+Sans&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;
:root{
    --cor-fundo: #251c1c;
    --cor-principal: #fff;
    --cor-secundario: #212121;
    --cor-fonte: #fff;
    --cor-linha-tabela: #212121;
    --cor-instagram: linear-gradient(45deg, #405de6, #5851db, #833ab4, #c13584, #e1306c, #fd1d1d);
}

*{
  margin: 0;
  padding: 0;
}
body{
  width: 100%;
  font-family: 'Inclusive Sans', sans-serif;
  margin: 0;
  padding: 0;
}

.focusedInput:focus {
  border-left: transparent;
  border-right: transparent;
  border-top: transparent;
  border-bottom:0px 10px solid #000; /* Example: solid black border */
  /* Adds padding above the bottom border */
  outline: none; /* Removes the default focus outline */
}


/* Estilos para cada caixa de contato */
.box-whats,
.box-instagram,
.box-git,
.box-link,
.box-face {
    width: 40px;
    height: 40px;
    margin-inline: 10px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s;
    
}

.box-whats:hover, .box-instagram:hover, .box-git:hover, .box-face:hover, .box-link:hover{
  transform: scale(1.1);
}


/* Exemplo de ícone usando font-awesome (substitua pelo seu próprio conteúdo) */
.box-whats{
    color: #FFFFFF;
    background-color: #25d366;
}

.box-face{
    color: #FFFFFF;
    background-color: #1877f2;
}

.box-instagram{
    color: #FFFFFF;
    background: linear-gradient(45deg, #405de6, #5851db, #833ab4, #c13584, #e1306c, #fd1d1d);
}

.box-git{
    background-color: #fff;
}

.box-link{
  color: #FFFFFF;
  background-color: #0178A3;
}
@media screen and (max-width: 768px) {
  .loca{
    margin-top: 100px;
  }
    .box-whats,
    .box-instagram,
    .box-git,
    .box-link,
    .box-face{
        width: 40px;
        height: 40px;
    }
    
}


 .titulo-quadro{
    margin-top: 16px;
 }
.quadro {  
  text-align: center;
  align-items: center;
  justify-content: center;
    color: #212121;
    background-color: #FFFFFF;
    border: none;
	  position: relative;
}


:root {
  --main-color: #F55E36;
}

