.download-btn {
    text-align: center;
    justify-content: center;
    width: 33%;
    cursor: pointer;
    background-color: white;
    color: black;
    font-size: 14px;
    padding: 6px 12px;
    border: 2px solid #F55E36;
    border-radius: 7px;
    display: inline-block;
    box-shadow: 2px 2px 5px #00000033;
    transition: background-color 0.3s ease;
    font-family: 'lato';
  }
  
  .download-btn:hover {
    background-color: #F55E36;
    color: white;
    border: 2px solid #F55E36;

  }